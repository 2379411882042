import React from "react";
import { MTBComingSoon } from "../../components";

const HomeMainView = () => {
  return (
    <>
      <MTBComingSoon />
    </>
  );
};

export default HomeMainView;