import './App.css';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import Router from './router/Router';
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const stripePromise = loadStripe(
  "pk_live_51OkXAwDRk98sgoxdAIh2V5fHVTAUsMCFrcHNYpNFJiCZeup3B70VYMTaakP2Av6dU5NXB8J9dddkO0dW0t9bTpXG00yT6Yn8iB"
);

function App() {
	return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Elements stripe={stripePromise}>
        <div className='App'>
          <Router />
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
          />
        </div>
      </Elements>
    </LocalizationProvider>
  );
}

export default App;

