import {mdiGlassCocktail, mdiAccount} from "@mdi/js";

export default [
    {
        "name": "Other",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Bar",
        "subcategories": [
            "Wine Bar",
            "Sports Bar",
            "Bar & Grill",
            "Dive Bar",
            "Pub"
        ],
        "text": ""
    },
    {
        "name": "Club",
        "subcategories": [
            "Beach Club",
            "Night Club",
            "Comedy Club",
            "Youth Club",
            "Strip Club",
            "Social Club"
        ],
        "text": ""
    },
    {
        "name": "Event Center",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Worship Center",
        "subcategories": [
            "Church",
            "Synagogue",
            "Temple",
            "Cathedral",
            "Mosque",
            "Shrine"
        ],
        "text": ""
    },
    {
        "name": "Religion",
        "subcategories": [
            "Christianity",
            "Judaism",
            "Islam",
            "Baptist",
            "Hinduism",
            "Buddhism",
            "Catholicism"
        ],
        "text": ""
    },
    {
        "name": "Restaurant",
        "subcategories": [
            "American",
            "Italian ",
            "Steakhouse",
            "Korean",
            "Tex Mex",
            "Taqueria"
        ],
        "text": ""
    },
    {
        "name": "Café",
        "subcategories": [
            "Coffee shop"
        ],
        "text": ""
    },
    {
        "name": "School",
        "subcategories": [
            "Elementary School",
            "Middle School",
            "High School",
            "Jr. High School"
        ],
        "text": ""
    },
    {
        "name": "Venue",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Night Life",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Sports",
        "subcategories": [
            "Football",
            "Basketball",
            "Soccer",
            "Softball",
            "Baseball",
            "Hockey",
            "Swimming",
            "Track",
            "Bowling"
        ],
        "text": ""
    },
    {
        "name": "Symphony",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "League",
        "subcategories": [
            "Little League",
            "Youth League",
            "Men's League",
            "Woman's League"
        ],
        "text": ""
    },
    {
        "name": "Library",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Lounge",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Lodge",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Food Truck",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Concert Hall",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Stadium",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Music",
        "subcategories": [
            "Hip-Hop",
            "Pop",
            "R&B",
            "Country",
            "Jazz",
            "Rock",
            "Reggae",
            "Reggaeton",
            "Classical"
        ],
        "text": ""
    },
    {
        "name": "Theater",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Store",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Artist",
        "subcategories": [
            "Music",
            "Artwork"
        ],
        "text": ""
    },
    {
        "name": "Museum",
        "subcategories": [
            "Art",
            "Science",
            "History"
        ],
        "text": ""
    },
    {
        "name": "Class",
        "subcategories": [
            "Cooking"
        ],
        "text": ""
    },
    {
        "name": "Ministry",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "University",
        "subcategories": [
            "Jr College",
            "Community College",
            "HBCU",
            "State college",
            "College",
            "Online"
        ],
        "text": ""
    },
    {
        "name": "Coach",
        "subcategories": [
            "Mentor",
            "Spiritual",
            "Life"
        ],
        "text": ""
    },
    {
        "name": "Gym",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Entertainer",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Hair Salon",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Amusement Park",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Park",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Charity",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Dance Hall",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Arena",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Arcade",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Ballet",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Brewery",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Bingo Hall",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Circus",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Coffee Shop",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Kids",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Gaming",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Zoo",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Diner",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Exhibition",
        "subcategories": [],
        "text": ""
    },
    {
        "name": "Health",
        "subcategories": [],
        "text": ""
    }
]