import React from 'react';
import { MTBComingSoon } from '../../components';
const Dashboard = () => {
  return (
    <>
      <MTBComingSoon />
    </>
  );
};

export default Dashboard;