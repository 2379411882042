import React from "react";
import {MTBComingSoon} from "../../components";

const AnalyticsView = () => {
  return (
    <>
      <MTBComingSoon />
    </>
  );
};

export default AnalyticsView;
